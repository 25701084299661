
import * as glideRuntime$B3hCJ3CrVU from '/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.22.0/node_modules/@nuxt/image/dist/runtime/providers/glide'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "glide",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['glide']: { provider: glideRuntime$B3hCJ3CrVU, defaults: {"baseURL":"https://app.365dagenbloemen.nl"} }
}
        